import { LoadingOverlay }  from "@mantine/core";
import {
	NavigationProgress,
	completeNavigationProgress,
	startNavigationProgress
} from "@mantine/nprogress";
import { useRouter }           from "next/router";
import { useEffect, useState } from "react";

import "@mantine/nprogress/styles.css";
import { useGetIsWebview } from "_common/hooks/global";

const RouterTransition = () => {
	const router    = useRouter ();
	const isWebview = useGetIsWebview ();

	const [ showWebviewLoader, setShowWebviewLoader ] = useState ( false );

	useEffect ( () => {
		const handleStart = url => {
			if ( isWebview ) {
				setShowWebviewLoader ( true );
			}
			url !== router.asPath && startNavigationProgress ();
		};

		const handleComplete = () => {
			if ( isWebview ) {
				setShowWebviewLoader ( false );
			}
			completeNavigationProgress ();
		};

		router.events.on ( "routeChangeStart", handleStart );
		router.events.on ( "routeChangeComplete", handleComplete );
		router.events.on ( "routeChangeError", handleComplete );

		return () => {
			router.events.off ( "routeChangeStart", handleStart );
			router.events.off ( "routeChangeComplete", handleComplete );
			router.events.off ( "routeChangeError", handleComplete );
		};
	}, [ router.asPath ] );

	if ( isWebview ) // loader for ios and android
		return (
			<LoadingOverlay
				loaderProps = { { size: "xl", color: "blue", variant: "circle" } }
				visible     = { showWebviewLoader }
				zIndex      = { 1001 }
			/>
		);

	return <NavigationProgress />;

};

export default RouterTransition;
